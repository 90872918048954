import BookingForm from '../pages/booking-form.js';
import { exists } from '../base/dom-utils.js';
import { ReservationHandler, UserHandler } from '../base/session-handler.js';
import { _isNotNull, formatNumber, replaceToken, EventHandler } from '../base/utils.js';
import { _LOCALE_, property_country_code } from '../base/vars.js';

class WyndhamRewardsEnroll {
  constructor() {
    if(exists('.wyndham-rewards')) {
      EventHandler.one('bookdata.complete', () => {
        let bookData = ReservationHandler.getBookData();
        this.autoenroll = bookData.autoEnroll;
        this.pacRatePlan = bookData.pacRate;
        this.pts = parseInt(bookData.qualPointsEarned.replace(/,/g, ''));
        this.isSelectCountryUS = (($('.country select').val() === 'US') || ($('.country select').val() === '') || ($('.country select').val() === null));
        this.isVariantUS = _LOCALE_ === 'en-us';

        this.populateTermsAndConditions();
        this.populatePointsEarned();

        this.determineCopyToShowByCountry();
      });
    }
  }

  populateTermsAndConditions() {
    BookingForm.populateTermsAndConditions('.wyndham-rewards .marketing-preferences .rm-header', '.wyndham-rewards #wr-read-more');
  }

  populatePointsEarned() {
    if(this.pts) {
      this.pts = (this.pts < 1000 && !this.pacRatePlan) ? '1,000' : formatNumber(this.pts);
      $('.point-replace,.nopoints-desc').each((i,el) => {
        let txt = replaceToken($(el).html(), '${ofPoints}', this.pts);
        if(txt) {
          $(el).html(txt);
        }
      });
    } else {
      $('.wyndham-rewards .country-points-desc, .wyndham-rewards .country-nopoints-desc').toggle();
    }
  }

  determineCopyToShowByCountry() {
    let pts = parseInt(this.pts);
    //Hide the rewards points banner if the total tax is less than or equal to 25 incident
    let bookData = ReservationHandler.getBookData(),
      totax = 0;
    if (_isNotNull(bookData) && _isNotNull(bookData.totatax)) {
      totax = parseFloat(bookData.totatax);
    }

    //DIG-1647 - show/hide points/no-points description
    $('.point-replace.description.us,.point-replace.description.non-us, .wyndham-rewards .points-desc, .wyndham-rewards .nopoints-desc').css('display','none');
    $('.nopoints-desc-logged').hide();
    //logged in user
    if (UserHandler.isWRLoggedIn()) {
      $('.nopoints-desc-logged').show();
      $('.reward-number-input').addClass('user-logged');
      document.getElementById('customerWRN').disabled = true;
      $('.reward-number-input label').text('Member Number: ');
      $('#customerWRN').attr('size', $('#customerWRN').val().length);
      //logged in user with US property
      if((property_country_code === 'US') && (pts > 0) && (this.autoenroll === false) && !(totax <= 25)) {
        $('.wyndham-rewards .points-desc').css('display','inline-block');
      } else if((property_country_code === 'US') && (pts > 0) && (this.autoenroll === true) && !(totax <= 25)) {
        $('.wyndham-rewards .points-desc').css('display','inline-block');
      } else if((property_country_code === 'US') && (pts <= 0)) {
        $('.wyndham-rewards .points-desc').css('display','none');
      } else if(!(property_country_code === 'US') && (pts > 0) && (this.autoenroll === false)) { // logged in user with Non-US property
        $('.wyndham-rewards .nopoints-desc').css('display','inline-block');
      } else if(!(property_country_code === 'US') && (pts > 0) && (this.autoenroll === true)) {
        $('.wyndham-rewards .nopoints-desc').css('display','inline-block');
      } else if(!(property_country_code === 'US') && (pts <= 0)) {
        $('.wyndham-rewards .nopoints-desc').css('display','none');
      }
    } else {
      $('.nopoints-desc-logged').hide();
      $('.wyndham-rewards .checkbox').show();

      if (this.autoenroll === true) {
        $('input[name="enroll"]').prop('required','required');
      }

      if (this.isSelectCountryUS && this.isVariantUS) {
        $('input[name="enroll"]').prop('checked', true);
        $('.mktg-wr input[type="checkbox"]').prop('checked', true);
        $('.mktg-wr').addClass('hide');
      } else {
        $('input[name="enroll"]').prop('checked', false);
        $('.mktg-wr input[type="checkbox"]').prop('checked', false);
        $('.mktg-wr').removeClass('hide');
      }


      //Guest user with US property with US user
      if ((property_country_code === 'US') && this.isSelectCountryUS) {
        if(pts > 0 && this.autoenroll === false && !(totax <= 25)) {
          $('.wyndham-rewards .points-desc').css('display','inline-block');
        } else if(pts > 0 && this.autoenroll === true && !(totax <= 25)) {
          $('.wyndham-rewards .points-desc').css('display','inline-block');
          $('.point-replace.description.us').css('display', 'inline-block');
          $('.wyndham-rewards').removeClass('non-us');
        } else if(pts > 0 && this.autoenroll === true && (totax <= 25)) {
          $('.point-replace.description.us').css('display', 'inline-block');
          $('.wyndham-rewards').removeClass('non-us');
        } else if(pts <= 0) {
          $('.wyndham-rewards .points-desc').css('display','none');
        }
      } else if ((property_country_code === 'US') && !this.isSelectCountryUS) {
        //Guest user with US property with Non US user
        if(pts > 0 && this.autoenroll === false && !(totax <= 25)) {
          $('.wyndham-rewards .points-desc').css('display','inline-block');
        } else if(pts > 0 && this.autoenroll === true && !(totax <= 25)) {
          $('.wyndham-rewards .points-desc').css('display','inline-block');
          $('.point-replace.description.non-us').css('display', 'inline-block');
          $('.wyndham-rewards').addClass('non-us');
        } else if(pts > 0 && this.autoenroll === true && (totax <= 25)) {
          $('.wyndham-rewards .points-desc').css('display','none');
          $('.point-replace.description.non-us').css('display', 'inline-block');
          $('.wyndham-rewards').addClass('non-us');
        } else if(pts <= 0){
          $('.wyndham-rewards .points-desc').css('display','none');
        }
      } else if (!(property_country_code === 'US') && this.isSelectCountryUS) {
        //Guest user with Non-US property with US user
        if(pts > 0 && this.autoenroll === false) {
          $('.wyndham-rewards .nopoints-desc').css('display','inline-block');
        } else if(pts > 0 && this.autoenroll === true) {
          $('.wyndham-rewards .nopoints-desc').css('display','inline-block');
          $('.point-replace.description.us').css('display', 'inline-block');
          $('.wyndham-rewards').removeClass('non-us');
        } else if(pts <= 0) {
          $('.wyndham-rewards .nopoints-desc').css('display','none');
        }
      } else if (!(property_country_code === 'US') && !this.isSelectCountryUS) {
        //Guest user with Non US property with Non-US user
        if(pts > 0 && this.autoenroll === false) {
          $('.wyndham-rewards .nopoints-desc').css('display','inline-block');
        } else if(pts > 0 && this.autoenroll === true) {
          $('.wyndham-rewards .nopoints-desc').css('display','inline-block');
          $('.point-replace.description.non-us').css('display', 'inline-block');
          $('.wyndham-rewards').addClass('non-us');
        } else if(pts <= 0) {
          $('.wyndham-rewards .nopoints-desc').css('display','none');
        }
      }

    }
  }
}

export default new WyndhamRewardsEnroll();
