import {
  $_HEADER_,
  $_PROMO_BANNER_,
  $_PAGE_HERO_,
  $_BOOKING_BAR_MAIN_,
  ONEDAY,
  _LANGUAGE_,
  _LOCALE_,
  _SEO_LOCALE_,
  isPublish,
  barPlusBonusList,
  $_PAGE_,
  brandMap,
  defaultDiacriticsRemovalMap,
  memberRatePlanList,
  additionalCorpCodes,
  instantHoldRateCodes,
  countryCodeList,
  property_city_name,
  property_country_code,
  property_country_name,
  property_state_code,
  property_state_name,
  _brandCodeString,
  _defaultAdults,
  _defaultChannelId,
  _defaultChildren,
  _defaultRooms,
  _maxFutureDays,
  _maxLengthOfStay,
  _minLengthOfStay,
  queryParams,
  _defaultSearchRadius,
  _defaultSmallSearchRadius,
  _defaultMediumSearchRadius,
  _defaultSearchRadiusKm,
  _defaultSmallSearchRadiusKm,
  _defaultMediumSearchRadiusKm,
  wrccRateCodes,
  everGreenRateCodes,
  mobileOnlyRateCodes,
  WR_AUTHENTICATED,
  SESSION_PROFILE,
  ACCESS_TOKEN,
  LOGLOCALE_COOKIE,
  pageHash,
  FTSI,
  ENROLLMENT
} from './vars.js';


function getHrefESApiEncoded() {
  const str = '' + window.location.href;
  return str;
}

function _isNumber(n) {
  return (typeof n === 'number' && !(isNaN(n)));
}

function _isValidBrand(id) {
  return (_isNotNull(id) && typeof brandMap === 'object' && id.toLowerCase() in brandMap);
}

function _isValidBrandTier(id) {
  return (_isNotNull(id) && typeof window.brandTierMap === 'object' && id.toLowerCase() in window.brandTierMap);
}

function _isWeakTrue(b) {
  return (
    b === 'true' ||
    b === true
  );
}

function _isWeakerTrue(b) {
  return (
    _isWeakTrue(b) ||
    (_isNotNull(b) && b.match(/^y$/i))
  );
}

function _isWeakFalse(b) {
  return (
    b === 'false' ||
    b === false
  );
}

function _isNotNull(v) {
  return (
    v !== null &&
    typeof (v) !== 'undefined' &&
    (
      v.length > 0 ||
      v.length === undefined // Account for booleans
    )
  );
}

function _isNull(v) {
  return (!(_isNotNull(v)));
}

function convertMonthsToDays(numOfMonths) {
  return numOfMonths * 30.436875;
}

function getToday() {
  return new Date();
}

function getTomorrow() {
  return new Date(Date.now() + ONEDAY);
}

function getNextDay(d) {
  return getXDays(1, d);
}

function getNumDays(start, end) {
  start = getDateFromDateString(start);
  end = getDateFromDateString(end);

  return Math.round((end.getTime() - start.getTime()) / ONEDAY);
}

function getXDays(x, d) {
  x *= 1;
  if (!(_isNumber(x))) {
    return;
  }

  return new Date(getDateFromDateString(d).getTime() + ONEDAY * x);
}

function _isDateType(d) {
  return (_isNotNull(d) && typeof (d) === 'object' && 'getDate' in d && d.toString() !== 'Invalid Date');
}

function getDateFromDateString(d) {
  if (_isDateType(d)) {
    return d;
  }
  if (_isNumber(d)) {
    return new Date(d);
  }

  if (typeof d === 'string' && d.length > 0) {
    let matches;
    if (d.match(/(\d{4})\W(\d{1,2})\W(\d{1,2})/)) { // YYYY MM DD
      matches = d.match(/(\d{4})\W(\d{1,2})\W(\d{1,2})/);
      return new Date(matches[1], matches[2] - 1, matches[3]);
    } else if (d.match(/(\d{1,2})\W(\d{1,2})\W(\d{2,4})/)) { // MM-DD-YY(YY)
      matches = d.match(/(\d{1,2})\W(\d{1,2})\W(\d{2,4})/);
      // Force four-digit year
      if (matches[3].length === 2) {
        matches[3] = 2000 + (matches[3] * 1);
      }
      return new Date(matches[3], matches[1] - 1, matches[2]);
    }
    return new Date(d);
  }
  return getToday();
}

function formatDateForBWS(d) {
  d = getDateFromDateString(d);
  let month = d.getMonth() * 1 + 1;

  return month + '/' + d.getDate() + '/' + d.getFullYear();
}

function formatDateForG360(d) {
  d = getDateFromDateString(d);
  let month = d.getMonth() * 1 + 1;
  month = (month < 10) ? '0' + month : month;
  let day = (d.getDate() < 10) ? '0' + d.getDate() : d.getDate();
  return  d.getFullYear() + '-' + month + '-' + day ;
}

function formatDateForSnowstorm(d) {
  d = getDateFromDateString(d);
  let month = d.getMonth() * 1 + 1;
  month = (month < 10) ? '0' + month : month;
  let day = (d.getDate() < 10) ? '0' + d.getDate() : d.getDate();
  return  d.getFullYear() + '/' + month + '/' + day ;
}

function formatDateForBWSAvailability(d = new Date()) {
  d = getDateFromDateString(d);
  let month = d.getMonth() * 1 + 1;
  month = (month < 10) ? '0' + month : month;
  let day = (d.getDate() < 10) ? '0' + d.getDate() : d.getDate();
  return month + '-' + day + '-' + d.getFullYear();
}

function formatDateForWHGAvailability(d = new Date()) {
  d = getDateFromDateString(d);
  let month = d.getMonth() * 1 + 1;
  month = (month < 10) ? '0' + month : month;
  let day = (d.getDate() < 10) ? '0' + d.getDate() : d.getDate();
  return  day + '-' + month + '-' + d.getFullYear();
}

function formatDateForMyCheck(d) {
  return getDateFromDateString(d).toISOString()
    .replace('T', ' ')
    .replace('Z', ' UTC')
    .replace(/\.[0-9]*/, '');
}

function formatDateForUplift(d) {
  d = getDateFromDateString(d);
  let month = d.getMonth() * 1 + 1;
  month = (month < 10) ? '0' + month : month;
  let day = (d.getDate() < 10) ? '0' + d.getDate() : d.getDate();
  return d.getFullYear() + month.toString() + day.toString();
}

function formatDateForPrinting(d, opts) {
  switch (opts) {
  case ('weekdayCompact'):
    if (_LOCALE_.match(/(en-us|en-ca)/)) {
      opts = 'D, mm/dd/y';
    } else if (_LOCALE_.match(/(zh-cn|ko-kr)/)) {
      opts = 'D, y/mm/dd';
    } else {
      opts = 'D, dd/mm/y';
    }
    break;

  case ('weekdayLong'):
    if (_LOCALE_.match(/(en-us|en-ca)/)) {
      opts = 'DD, MM dd, yy';
    } else if (_LOCALE_.match(/zh-cn|ko-kr/)) {
      opts = 'DD, yy MM dd';
    } else {
      opts = 'DD, dd MM yy';
    }
    break;

  case ('textMonth'):
    if (_LOCALE_.match(/(zh-cn|ko-kr)/)) {
      opts = 'D, yy M dd';
    } else if (_LOCALE_.match(/(de-de|tr-tr|pt-br|es-xl|fr-ca|es-es|en-gb|el-gr)/)) {
      opts = 'D, dd M yy';
    } else {
      opts = 'D, M dd yy';
    }
    break;

  case ('textMonthLong'):
    if (_LOCALE_.match(/(en-us|en-ca)/)) {
      opts = 'MM dd, yy';
    } else if (_LOCALE_.match(/zh-cn|ko-kr/)) {
      opts = 'yy MM dd';
    } else {
      opts = 'dd MM yy';
    }
    break;

  case ('shortYear'):
    if (_LOCALE_.match(/(zh-cn|ko-kr)/)) {
      opts = 'y/mm/dd';
    } else if (_LANGUAGE_.match(/en/i)) {
      opts = 'mm/dd/y';
    } else {
      opts = 'dd/mm/y';
    }
    break;

  case ('weekdayCompactAlt'):
    if (_LOCALE_.match(/(en-us|en-ca)/)) {
      opts = 'D mm/dd/y';
    } else if (_LOCALE_.match(/(zh-cn|ko-kr)/)) {
      opts = 'D y/mm/dd';
    } else {
      opts = 'D dd/mm/y';
    }
    break;

  default:
    if (_LOCALE_.match(/(zh-cn|ko-kr)/)) {
      opts = 'yy/mm/dd';
    } else if (_LANGUAGE_.match(/en/i)) {
      opts = 'mm/dd/yy';
    } else {
      opts = 'dd/mm/yy';
    }
    break;
  }

  return $.datepicker.formatDate(opts, getDateFromDateString(d));
}

//Compares only year/month/day to determine if it is today
function isToday(d) {
  const today = getToday();
  return d.getDate() == today.getDate() &&
    d.getMonth() == today.getMonth() &&
    d.getFullYear() == today.getFullYear();
}

//This might fail if "d" is earlier in the same
function isPriorDate(d, t) {
  d = getDateFromDateString(d);
  t = getDateFromDateString(t);

  d.setHours(0, 0, 0, 0);
  t.setHours(0, 0, 0, 0);

  return (d.getTime() <= t.getTime());
}

function updateCurrencySelectorCode(code, rate){
  try {
    if (code && !rate.currency_code && !$(".page").is(".search-results-page")){
      $(".currency-converter").text(code);
      $(".currency-converter").append(" <span class='currency-chevron'></span>");
    }else if(code == '' && !rate.currency_code && !$(".page").is(".search-results-page")){
      $(".currency-converter").text('');
      $(".currency-converter").append(" <span class='currency-chevron'></span>");
    }else if (rate.currency_code){
      $(".currency-converter").text(rate.currency_code);
      $(".currency-converter").append(" <span class='currency-chevron'></span>");
    }else if(code == '' && !rate.currency_code &&  $('.page').is('.search-results-page-v2')){
      $(".currency-converter").text('Currency');
      $(".currency-converter").append(" <span class='currency-chevron'></span>");
    }
    if ($(".currency-converter").html() && $(".currency-converter").html().split(' ')[0] == "USD"){
      $(".currency-converter").prepend('$');
    }
  } catch(error) {
    console.log("ERROR: Could not update the currency code - ", error );
  }
}

function getCurrencyMapping(code,original = false) {
  updateCurrencySelectorCode(code, currencyRate);

  if($('.page').is('.packages-page') && code === 'USD' && ($.isEmptyObject(currencyRate) || currencyRate.currency_code === code || original)){
    return '$';
  }
  if (code === 'USD' && ($.isEmptyObject(currencyRate) || currencyRate.currency_code === code || ($('.page').is('.booking-page') && !$('.page').is('.packages-page')) || original)) {
    return '$';
  }
  return '';
}

function convertToCurrency(price,currency,original = false) {
  if(typeof price != 'undefined'){
    price = price.toString().replace(/,/g,'');
  }

  if(original){
    return parseFloat(price).toFixed(2);
  }

  if(currencyRate.currency_code && currency !== 'USD' && (currencyRate.currency_code !== currency)){
    const currencyRates = localStorage['currencies'] && JSON.parse(localStorage['currencies']).value;
    const existingRate =  currencyRates.reduce((result , item) => {
      return (item.currency_code === currency && item) ? item : result;
    },null);
    return parseFloat(parseFloat(currencyRate.exchange_rate) * (parseFloat(price) / parseFloat(existingRate.exchange_rate))).toFixed(2) ;
  }
  if(currencyRate.currency_code && currencyRate.currency_code !== currency){
    return parseFloat(parseFloat(currencyRate.exchange_rate) * parseFloat(price)).toFixed(2);
  }else{
    return parseFloat(price).toFixed(2);
  }
}
function getSelectedCurencyCode(currencyCode,original = false) {
  if(currencyRate.currency_code && !original){
    return currencyRate.currency_code;
  }else{
    return currencyCode;
  }
}

function isFeesInclude(priceDisplayType){
  //RRT", "TPD", "TNF", "CMA"
  if(priceDisplayType === 'TPD' || priceDisplayType === 'CMA'){
    return true;
  }else{
    return false;
  }
}

let currencyConverterCookieName = 'selected-currency';

function uppercaseFirstLetter(str) {
  if (str) {
    return (str.charAt(0).toUpperCase() + str.substr(1).toLowerCase());
  } else {
    return '';
  }
}

function uppercaseFirstLetterEachWord(str) {
  return str.replace(/\w\S*/g, function(txt) {
    return uppercaseFirstLetter(txt);
  });
}

function hyphenToCamel(str) {
  str = str.replace(/-/g, ' ');
  str = uppercaseFirstLetterEachWord(str);
  str = str.replace(str[0], str[0].toLowerCase());
  str = str.replace(/ /g, '');
  return str;
}
/**
 * Wrapper function to sort an object alphaetically by a provided key.
 * @param locale - Language code to be used for alphabetical sort
 * @param list - List of objects to be sorted alphabetically
 * @param key - Property of the objects passed to use for sorting.
 */
function sortObjectList(locale, list, key) {
  list.sort((a, b) => {
    if(typeof Intl !== 'undefined' && Intl) {
      return new Intl.Collator(locale).compare(a[key], b[key]);
    } else {
      return a[key].localeCompare(b[key]);
    }
  });
  return list;
}

function handlePostSubtotal(subtotal, giftCardPrice, currency, ratePlan) {
  if (!(isGoFreeRate(ratePlan))) {
    subtotal = parseFloat(subtotal);

    if (giftCardPrice) {
      giftCardPrice = parseFloat(giftCardPrice);

      subtotal += giftCardPrice;
    }

    return getCurrencyMapping(currency) + subtotal.toFixed(2) + ' ' + currency;
  }

  return '';
}

function isBarRate(rp) {
  return ((rp) ? (rp.match(/\b(LV1|RROD)\b/ig)) : false);
}

function isHoldRate(rp) {
  if (_isNotNull(additionalCorpCodes) && _isNotNull(rp)) {
	var re = new RegExp('\\b(' + additionalCorpCodes + ')\\b', 'ig');
	return (rp) ? rp.match(re) : false;
  } else {
    return false;
  }
}

function isEverGreenRate(rp) {
  if (_isNotNull(everGreenRateCodes) && _isNotNull(rp)) {
    var re = new RegExp('\\b(' + everGreenRateCodes.split(',').join('|') + ')\\b', 'ig');
    return (rp) ? rp.match(re) : false;
  }
  return false;
}

function isMobileOnlyRate(rp) {
  if (_isNotNull(mobileOnlyRateCodes) && _isNotNull(rp)) {
    var re = new RegExp('\\b(' + mobileOnlyRateCodes.split(',').join('|') + ')\\b', 'ig');
    return (rp) ? rp.match(re) : false;
  }
  return false;
}

//Checks if the location from where the user access is part of the allowed regions where "All In-price" feature will be displayed
function isAllInPriceRegion() {
  let countryCode = CookieHandler.readCookie('country_code');
  let regionCode = CookieHandler.readCookie('region_code');
  let countryRegionCode = _isNotNull(regionCode) ? countryCode + '/' + regionCode : '';

  if (_isNotNull(countryCodeList) && _isNotNull(countryCode)) {
    let list = countryCodeList.replace(/ /g,'');
    let re = new RegExp('\\b(' + list.split(',').join('|') + ')\\b', 'ig');
    if (_isNotNull(countryRegionCode)) {
      return countryCode.match(re) || countryRegionCode.match(re);
    }
    return countryCode.match(re);
  }
  return false;
}

function isSMORate(rp) {
  if (_isNotNull(instantHoldRateCodes)) {
    var re = new RegExp('\\b(' + instantHoldRateCodes.split(',').join('|') + ')\\b', 'ig');
	return (rp) ? rp.match(re) : false;
  } else {
    return false;
  }
}

function isBonusRP(rp) {
  var re = new RegExp('\\b(' + barPlusBonusList.join('|') + ')\\b', 'ig');
  return (rp) ? rp.match(re) : false;
}

function isSelectedRate(optedRate, rp) {
  if (getCodesLowercase(rp) === getCodesLowercase(optedRate.ratePlan) || getCodesLowercase(rp) === getCodesLowercase(optedRate.rateCode) || getCodesLowercase(rp) === getCodesLowercase(optedRate.groupCode) || getCodesLowercase(rp) === getCodesLowercase(optedRate.corpCode)) {
    return true;
  } else {
    return false;
  }
}

function getCodesLowercase(code) {
  if (code != null) {
    return code.toString().toLowerCase();
  }
  return code;
}

function PickSelectedRatePlan(optedRate) {
  if (optedRate.corpCode != null || optedRate.corpCode != '') {
    return optedRate.corpCode;
  } else {
    return null;
  }
}

function isGoFastRate(rp) {
  return ((rp) ? (rp.match(/\b(SRB0|SRB1|SRB2|SRB3|SRB4|SRB5|SRB6|BPRTRP2|BWRFSR)\b/ig)) : false);
}

function isGoFreeRate(rp) {
  return ((rp) ? (rp.match(/\b(SRB0A|SRB|SRB2A|SRB3A|SRB4A|BPRTRP1|BWRFRR|SRBFR)\b/ig)) : false);
}

function isLoyaltyRP(rp) {
  return ((rp) ? (isGoFastRate(rp) || isGoFreeRate(rp)) : false);
}

function isMemberRate(rp) {
  if (_isNotNull(memberRatePlanList)) {
    let re = new RegExp('\\b(' + memberRatePlanList.split(',').join('|') + ')\\b', 'ig');
    return (rp) ? rp.match(re) : false;
  } else {
    return false;
  }
}

function isWRCCRate(rp) {
  if (_isNotNull(wrccRateCodes)) {
    let re = new RegExp('\\b(' + wrccRateCodes + ')\\b', 'ig');
    return (rp) ? rp.match(re) : false;
  } else {
    return false;
  }
}

//Checks if the rate is one from the "Rate Plans to Exlcude.xls" (49 in total)
function isFlexibleRate(rateCode) {
  return (window.cmRatePlanExclusion.indexOf(rateCode.toUpperCase()) !== -1) ? false : true;
}

//Checks if the Confidence Messages(Components) from Booking page are part of the excluded property
function isBookMessagingForcedToHide(propertyId) {
  let regExp  = new RegExp('\\b(' + window.cmPropertyExclusion + ')\\b', 'ig');
  return (propertyId) ? propertyId.match(regExp) : false;
}

function isStrikeThroughRateEnable(searchServiceData) {
  let isRateStrikedThrough = false;
  if (searchServiceData && searchServiceData.otherAmenities) {
    searchServiceData.otherAmenities.forEach((amenity) => {
      if (amenity.groupDetails) {
        let strikeAmenity = amenity.groupDetails.find(amenityData => {
          return amenityData.amenityName == 'STRIKERTE';
        });
        if (strikeAmenity) {
          isRateStrikedThrough = true;
        }
      }
    });
  }
  return isRateStrikedThrough;
}

function isBNPLEX(searchServiceData) {
  let isRateStrikedThrough = false;
  if (searchServiceData && searchServiceData.otherAmenities) {
    searchServiceData.otherAmenities.forEach((amenity) => {
      if (amenity.groupDetails) {
        let strikeAmenity = amenity.groupDetails.find(amenityData => {
          return amenityData.amenityName == 'BNPLEX';
        });
        if (strikeAmenity) {
          isRateStrikedThrough = true;
        }
      }
    });
  }
  return isRateStrikedThrough;
}

/**
 * Sort rate plans for each room the object given. Rates are
 * sorted by price, and then order is decided by business rules.
 * @rooms the rooms object which contains the rates to be sorted
 * @optedRate the criteria object which contains the bookingSelection values
 * @isAuthenticaed Boolean if the user is authenticated
 * @isMobileWidth Boolean
*/
function sortRoomRatePlans(rooms, optedRate, isAuthenticated, isMobileWidth) {
  let optedRatePlan = PickSelectedRatePlan(optedRate);
  let optedRateFound = false;
  $.each(rooms, (_c, room) => {
    let rates = room.rates;
    let selectedRate = '';
    //Remove the rates marked as "onlyMobile"
    rates = (isMobileWidth) ? rates : rates.filter(rate => !isMobileOnlyRate(rate.ratePlanId));
    if (rates.length > 1) {
      let barRate = rates.filter(rate => isBarRate(rate.ratePlanId))[0];
      let holdRate = rates.filter(rate => isHoldRate(rate.corpCode))[0];
      let memberRate = rates.filter(rate => isMemberRate(rate.ratePlanId))[0];
      let wrccRate = rates.filter(rate => isWRCCRate(rate.ratePlanId))[0];
      let selectedRates = getMultipleSelectedRates(optedRate, optedRatePlan, rates);
      selectedRate = selectedRates[0];
      //Sorting rates by price low to high and setting above rates
      rates = rates.sort((a, b) => a.averageBeforeTax - b.averageBeforeTax);

      //Rates display Order for Mobile
      if (isMobileWidth) {
        rates = handleMemberRate(memberRate, wrccRate, rates);
        if (isAuthenticated) { //Scenario: Authenticated
          if (selectedRate) {//** Scenario: Authenticated - Qualified Search **
            //Third Row: MemberRate / CardHolderRate
            setMemberRate(memberRate, rates);
            setWrccRate(wrccRate, rates);
            //Second Row: BestAvailable
            setBestAvailableRate(barRate, rates);
            //First Row: Requested Rate
            setRequestedRate(selectedRates, rates);
          } else { //** Scenario: Authenticated - General Search **
            //Second Row: MemberRate / CardHolderRate
            setMemberRate(memberRate, rates);
            setWrccRate(wrccRate, rates);
            //First Row: BestAvailable
            setBestAvailableRate(barRate, rates);
          }
        } else { //Scenario: Unauthenticated
          if (selectedRate) { //** Scenario: Unauthenticated - Qualified Search **
            //Deselect the hold rate if holdRate and selectedRate are same, else both rates get appended making duplicates.
            if (holdRate && selectedRate.corpCode === holdRate.corpCode) {
              holdRate = '';
            }
            //Four row: BestAvailable
            setBestAvailableRate(barRate, rates);
            //Third row: InstantHold
            setInstantHoldRate(holdRate, rates);
            //Second Row: Evergreen
            setEverGreenRate(rates, isMobileWidth);
            //First Row: Requested Rate
            setRequestedRate(selectedRates, rates);
          } else { //** Scenario: Unauthenticated - General Search **
            //Third Row: BestAvailable
            setBestAvailableRate(barRate, rates);
            //Second Row: InstantHold
            setInstantHoldRate(holdRate, rates);
            //First Row: Evergreen
            setEverGreenRate(rates, isMobileWidth);
          }
        }
      } else { //Rates display Order for Desktop / Tablet
        if (selectedRate) {
          //put logic to deselect the hold rate if holdRate and selectedRate are same, else both rates get appended making duplicates.
          if (holdRate && selectedRate.corpCode === holdRate.corpCode) {
            holdRate = '';
          }
          setInstantHoldRate(holdRate, rates);
          setRequestedRate(selectedRates, rates);
        } else {
          //This will take precedence only when selected rate is not given by user i.e.default search
          // Bar rate - Member only rate - all rates low to high
          setMemberRate(memberRate, rates);
          setWrccRate(wrccRate, rates);
          setBestAvailableRate(barRate, rates);
          setInstantHoldRate(holdRate, rates);
        }
      }
    } else if (rates.length == 1) {
      if (isSelectedRate(optedRate, rates[0].ratePlanId)) {
        selectedRate = rates[0];
      } else if (!rates[0].alternateRate && rates[0].corpCode == optedRatePlan) {
        selectedRate = rates[0];
      } else if (rates[0].corpCode && isHoldRate(rates[0].corpCode)) {
        selectedRate = rates[0];
      }
    }
    //Check if the selectedRate by user is found
    if (!optedRateFound) {
      if ((optedRate.ratePlan == null && optedRate.rateCode == null && optedRate.corpCode == null && optedRate.groupCode ==
          null) || (selectedRate && (selectedRate !== undefined || selectedRate !== 'undefined'))) {
        $('.rate-not-found').hide();
        optedRateFound = true;
      } else {
        $('.rate-not-found').show();
      }
    }

    room.rates = rates;
  });

  return rooms;
}
//Make sure only WRCCRate is displayed over MemberRate
function handleMemberRate(memberRate, wrccRate, rates) {
  if (memberRate && wrccRate) {
    return rates.filter(rate => !isMemberRate(rate.ratePlanId));
  }
  return rates;
}

function getMultipleSelectedRates(optedRate, optedRatePlan, rates) {
  let selectedRates = [];
  for (let j = 0; j < rates.length; j++) {
    if (isSelectedRate(optedRate, rates[j].ratePlanId)) {
      let currentRatePlan = rates.splice(j, 1);
      selectedRates.push(currentRatePlan[0]);
      j--;
    } else if (!rates[j].alternateRate && rates[j].corpCode == optedRatePlan) {
      let currentRatePlan = rates.splice(j, 1);
      selectedRates.push(currentRatePlan[0]);
      j--;
    }
  }
  if (selectedRates.length > 1) {
    selectedRates.sort((a, b) => {
      return (a.averageBeforeTax - b.averageBeforeTax);
    });
  }
  return selectedRates;
}

function getEverGreenRates(rates) {
  let everGreenRates = rates.filter(rate => isEverGreenRate(rate.ratePlanId));
  if (everGreenRates.length > 1) {
    everGreenRates = everGreenRates.sort(function(a, b) {
      return (b.averageBeforeTax - a.averageBeforeTax);
    });
  }
  return everGreenRates;
}

function getMemberRatesOnly(rates) {
  let memberRatesOnly = [];
  for (let j = 0; j < rates.length; j++) {
    if (isMemberRate(rates[j].ratePlanId)) {
      let currentRatePlan = rates.splice(j, 1);
      memberRatesOnly.push(currentRatePlan[0]);
      j--;
    }
  }
  //sort by price in descending order
  if (memberRatesOnly.length > 1) {
    memberRatesOnly = memberRatesOnly.sort(function(a, b) {
      return (b.averageBeforeTax - a.averageBeforeTax);
    });
  }
  return memberRatesOnly;
}

function getWrccRatesOnly(rates) {
  let wrccRatesOnly = [];
  for (let j = 0; j < rates.length; j++) {
    if (isWRCCRate(rates[j].ratePlanId)) {
      let currentRatePlan = rates.splice(j, 1);
      wrccRatesOnly.push(currentRatePlan[0]);
      j--;
    }
  }
  if (wrccRatesOnly && wrccRatesOnly.length > 1) {
    wrccRatesOnly = wrccRatesOnly.sort(function(a, b) {
      return (b.averageBeforeTax - a.averageBeforeTax);
    });
  }
  return wrccRatesOnly;
}

function setRequestedRate(selectedRates, rates) {
  for (let j = (selectedRates.length - 1); j >= 0; j--) {
    rates.unshift(selectedRates[j]);
  }
}

function isDateInBetween(dateFrom,dateTo,dateCheck){
  //DAI-45232: replacing '-' with '/' for IOS device fix
  var splitFrom = formatDateForBWSAvailability(dateFrom).split("-");
  var from = new Date(splitFrom[2],parseInt(splitFrom[0]) - 1,splitFrom[1]);
  var splitTo = formatDateForBWSAvailability(dateTo).split("-");
  let to   = new Date(splitTo[2],parseInt(splitTo[0]) - 1,splitTo[1]);
  var splitCheck = formatDateForBWSAvailability(dateCheck).split("-");
  let check = new Date(splitCheck[2],parseInt(splitCheck[0]) - 1,splitCheck[1]);

  console.log(from,to,check);
  if((check <= to && check >= from)){
    return true;
  }
}


function setMemberRate(memberRate, rates) {
  let memberRatesOnly = getMemberRatesOnly(rates);
  if (memberRatesOnly.length > 1) {
    //Add back to rates object
    for (let j = 0; j < memberRatesOnly.length; j++) {
      rates.unshift(memberRatesOnly[j]);
    }
  } else {
    if (memberRate) {
      rates.unshift(memberRate);
    }
  }
}

function setBestAvailableRate(barRate, rates) {
  if (barRate) {
    rates.splice(rates.indexOf(barRate), 1);
    rates.unshift(barRate);
  }
}

function setWrccRate(wrccRate, rates) {
  let wrccRatesOnly = getWrccRatesOnly(rates);
  if (wrccRatesOnly && wrccRatesOnly.length > 1) {
    //Add back to rates object
    for (let j = 0; j < wrccRatesOnly.length; j++) {
      rates.unshift(wrccRatesOnly[j]);
    }
  } else {
    if (wrccRate) {
      rates.unshift(wrccRate);
    }
  }
}

function setInstantHoldRate(holdRate, rates) {
  if (holdRate) {
    rates.splice(rates.indexOf(holdRate), 1);
    rates.unshift(holdRate);
  }
}

//If it is a mobile this will move the rate at beginning of the array otherwise will remove it
function setEverGreenRate(rates, isMobileWidth) {
  let everGreenRates = getEverGreenRates(rates);
  for (let j = 0; j < everGreenRates.length; j++) {
    rates.splice(rates.indexOf(everGreenRates[j]), 1);
    if (isMobileWidth) {
      rates.unshift(everGreenRates[j]);
    }
  }
}

function formatNumber(num) {
  if (typeof (num) === 'undefined' || _isNull(num)) {
    return;
  }
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

function toMoney(num) {
  return parseFloat(num).toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

function formatTimeShow(h) {
  if (!h) return '';
  while (h.length < 4) h = '0' + h;
  if (/^[\d]+$/.test(h)) {
    let t = h.match(/.{1,2}/g),
      m = t[1];
    h = t[0];
    if (_LOCALE_ === 'en-us') {
      let s = (h % 24 < 12) ? 'am' : 'pm';
      h = h % 12 || 12;
      return (h + ':' + m + ' ' + s);
    } else {
      return (h + ':' + m);
    }
  } else {
    return h;
  }
}

function printDollarAmount(amount, currency, original = false) {
  return (currency) ? (
    '<span class="currency-symbol">' + getCurrencyMapping(currency,(!$_PAGE_.is('.packages-page') || original)) + '</span>' +
    parseFloat(convertToCurrency(amount,currency,(!$_PAGE_.is('.packages-page') || original))).toFixed(2) +
    ' <span class="currency-code">' + getSelectedCurencyCode(currency,(!$_PAGE_.is('.packages-page') || original)) + '</span>'
  ) : null;
}
function printDollarAmountonPayment(amount, currency, original = false) {
  return (currency) ? (
    '<div class="value-grid"><span>'
     + getCurrencyMapping(currency,(!$_PAGE_.is('.packages-page') || original)) + parseFloat(convertToCurrency(amount,currency,(!$_PAGE_.is('.packages-page') || original))).toFixed(2).split('.')[0] +
    '<span class="sop">' +
     parseFloat(convertToCurrency(amount,currency,(!$_PAGE_.is('.packages-page') || original))).toFixed(2).split('.')[1] +
     '</span></span></div><span class="payment-curency">' + getSelectedCurencyCode(currency,!$_PAGE_.is('.packages-page')) + '</span>'
  ) : null;
}

function getISOFormatDate(date) {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join('-');
}

function getDateFormatFromISO(isoDate) {
  let d = '',
    parsed;
  if (isoDate.length === 10) {
    parsed = Date.parse(isoDate);
    if (isNaN(parsed) && isoDate.indexOf('-') > 0) {
      // IOS issue: in case date with dashes doesn't, then try using slashes.
      d = new Date(isoDate.replace(/-/g,'/'));
    } else {
      d = new Date(isoDate);
    }
  }
  return d;
}

function escapeRegExp(str) {
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
}

function extractDataFromObject(arg, object) {
  if (typeof arg === 'string' && arg in object) {
    return object[arg];
  } else if (typeof arg === 'object' && 'pop' in arg) {
    let obj = {};

    for (let i = 0; i < arg.length; i++) {
      let ect = arg[i];

      if (ect in object) {
        obj[ect] = object[ect];
      }
    }

    return obj;
  }

  return null;
}

function removeDiacritics(str) {
  for (let i = 0; i < defaultDiacriticsRemovalMap.length; i++) {
    str = str.replace(defaultDiacriticsRemovalMap[i].letters, defaultDiacriticsRemovalMap[i].base);
  }
  return str;
}

function getSecureHostedDomainUrl() {
  let domainUrl = '//' + window.location.host;
  return domainUrl;
}

function once(fn, context) {
  let result;

  return () => {
    if (fn) {
      result = fn.apply(context || this, arguments);
      fn = null;
    }

    return result;
  };
}

function debounce(func, wait, immediate) {
  let timeout;

  return () => {
    var context = this,
      args = arguments;

    let later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

function replaceToken(string, token, value) {
  let pattern = new RegExp(escapeRegExp(token), 'ig');

  if (typeof string != 'undefined' && string != null) {
    if (string.match(pattern)) {
      return string.replace(pattern, value);
    }
  }

  return false;
}

function buildLocationDetailsFromProperty() {
  if (typeof (window.propertyInfo) !== 'object') {
    window.propertyInfo = {};
  }

  return {
    city: property_city_name || window.propertyInfo.propertyCity || null,
    state: property_state_name || window.propertyInfo.propertyState || null,
    stateCode: property_state_code || null,
    country: property_country_name || window.propertyInfo.propertyCountry || null
  };
}

function getDefaultCriteria() {
  const defaultLos = $_BOOKING_BAR_MAIN_.find('.booking-dates-container').data('default-los');
  const today = formatDateForBWS(new Date());
  const tomorrow = formatDateForBWS(new Date(new Date().getTime() + ONEDAY));
  const todayPlusDefaultLos = formatDateForBWS(getXDays(parseInt(defaultLos, 10), new Date()));

  return {
    sessionId: Math.floor(Date.now() / 1000),
    brandCodeString: _brandCodeString,
    rooms: _defaultRooms,
    adults: _defaultAdults,
    children: _defaultChildren,
    maxFutureDays: _maxFutureDays,
    maxLengthOfStay: _maxLengthOfStay,
    minLengthOfStay: _minLengthOfStay,
    checkInDate: today,
    checkOutDate: defaultLos ? todayPlusDefaultLos : tomorrow,
    defaultLos: defaultLos ? defaultLos : 1,
    channelId: _defaultChannelId,
    updated: false
  };
}

function updateQueryStringParameter(uri, key, value) {
  var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
  var separator = uri.indexOf('?') !== -1 ? '&' : '?';
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + '=' + value + '$2');
  } else {
    return uri + separator + key + '=' + value;
  }
}

function serializeObject(el) {
  if (!el.length) {
    return false;
  }
  let $el = el,
    data = {},
    lookup = data;
  $el.find(':input[type!="checkbox"][type!="radio"], input:checked').each((index, el) => {
    let named = el.name.replace(/\[([^\]]+)?\]/g, ',$1').split(','),
      cap = named.length - 1,
      i = 0;
    if (named[0]) {
      for (; i < cap; i++) {
        lookup = lookup[named[i]] = lookup[named[i]] || (named[i + 1] == '' ? [] : {});
      }
      if ($(el).val() != '' && $(el).val() != 0) {
        if (lookup.length != undefined) {
          lookup.push($(el).val());
        } else {
          lookup[named[cap]] = $(el).val();
        }
      }
      lookup = data;
    }
  });
  return data;
}

function isValidNumber(num) {
  return (_.isNumber(num) || _.isString(num)) && !isNaN(num);
}

function shuffle(array) {
  let m = array.length,
    t, i;

  // While there remain elements to shuffle…
  while (m) {

    // Pick a remaining element…
    i = Math.floor(Math.random() * m--);

    // And swap it with the current element.
    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }

  return array;
}

function findValueByKey(object, key) {
  // Find case insensitive key in object, return value
  return _.isObject(object) && key ? object[Object.keys(object).find(k => k.toLowerCase() === key.toLowerCase())] : null;
}

function getLocaleUrlToken() {
  return (_SEO_LOCALE_ === 'en-us' ? '' : '/' + _SEO_LOCALE_);
}

function getHotelLocaleUrlToken() {
  let token = '/' + _SEO_LOCALE_;
  if (_SEO_LOCALE_ === 'en-ap') {
    token = '/en-uk';
  } else if (_SEO_LOCALE_ === 'en-us') {
    token = '';
  }
  return token;
}
// Search Key in Object or Key:Value
function isMemberAttr(obj, prop, query) {
  let isFounded = false;
  for (let key in obj) {

    // Search for Key
    if (obj.hasOwnProperty(prop)) {
      if (query === '') {
        return true;
      } else { // Search for key:value
        if (obj[key] === query) {
          return true;
        }
      }
    } else { // go deep into object elements
      // loop into arrays
      // Ignore stack array if its already founded
      if (Array.isArray(obj[key]) && !isFounded) {
        obj[key].forEach(function(element) {
          if (isMemberAttr(element, prop, query)) {
            isFounded = true;
            return true;
          }
        });
      } else {
        // search into next child if it is an object
        // ignore stack if its already founded
        if (typeof obj[key] === 'object' && !isFounded) {
          if (isMemberAttr(obj[key], prop, query)) {
            isFounded = true;
            return true;
          }
        }
      }
    }
  }
  return isFounded;
}

function getHeaderHeight() {
  return $_HEADER_.outerHeight() || 0;
}

function getRetailBannerHeight() {
  return hasRetailBanner() ? $('.retail-banner-component').outerHeight() : 0;
}

function hasRetailBanner() {
  return $('.retail-banner-component').length > 0;
}

function getPromoHeight() {
  return $_PROMO_BANNER_.outerHeight() || 0;
}

function getHeroHeight() {
  return $_PAGE_HERO_.outerHeight() || 0;
}

function getBookingBarHeight() {
  return $_BOOKING_BAR_MAIN_.outerHeight() || 0;
}

function isiOS() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

/**
 * Observes changes in src attributes for elements
 *
 * @cssClass class to observe.
 * @callback function to execute after observe is triggered.
 **/
function observeSrcMutations(cssClass, callback) {
  let targetNode = [].slice.call(document.querySelectorAll(cssClass));
  let observer = new MutationObserver((mutationsList, observer) => {
    for (let mutation of mutationsList) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'src' && typeof callback === 'function') {
        callback(mutation);
      }
    }
  });
  // Create an observer instance linked to the callback function for each element.
  targetNode.forEach(function(element) {
    observer.observe(element, {
      attributes: true
    });
  });
}

/**
 * detect IE
 * returns true for IE or false, if browser is not Internet Explorer
 */
function isIE() {
  var ua = window.navigator.userAgent;

  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return return true
    return true;
  }

  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return return true
    return true;
  }

  var edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return true
    return true;
  }

  // other browser
  return false;
}


// QUERY STRING HANDLER

let _queryString = window.location.search.replace(/\?/, '');

function getQueryString() {
  return _queryString;
}

function setQueryString(str) {
  _queryString = str.replace(/\?/, '');
}

function updateQueryParameter(key, val) {
  let re = new RegExp('(' + escapeRegExp(key) + '=).*?(\&)'),
    current = getQueryString() + '&';

  if (queryParams.indexOf(key) > -1 && _isNotNull(val)) {
    let m;
    if (current.match(re)) {
      m = current.match(re);
      let temp = {};

      temp[m[1].slice(0, -1)] = val;

      setQueryString(current.replace(re, '$1' + val + '$2').replace(/\&$/, ''));
    } else {
      let str = current.replace(/\&$/, '');
      str += (str.length > 0) ? '&' : '';
      setQueryString(str + key + '=' + val);
    }
  } else {
    setQueryString(current.replace(re, '').replace(/\&$/, ''));
  }
}

function updateSearchParameters(key, value) {
  let searchParams = new URLSearchParams(window.location.search);
  searchParams.set(key, value);

  if (window.history.replaceState) {
    const url = window.location.protocol
    + '//' + window.location.host
    + window.location.pathname
    + '?'
    + decodeURI(searchParams.toString());

    window.history.replaceState({
      path: url
    }, '', url);
  }
}

function populateObjectFromQueryString(string, ignoreEmptyVals) {
  if (ignoreEmptyVals !== true && ignoreEmptyVals !== false) {
    ignoreEmptyVals = false;
  }

  let decode = decodeURIComponent(string),
    obj = {};

  decode.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
    if ((value !== '' || !(ignoreEmptyVals))) {
      obj[key] = value;
    }
  });

  if (!obj.checkInDate || (obj.checkInDate && (obj.checkInDate === ''  || isNaN(Date.parse(obj.checkInDate))))) {
    if (!obj.checkin_date || (obj.checkin_date && (obj.checkin_date === ''  || isNaN(Date.parse(obj.checkin_date))))) {
      obj.checkin_date = getDefaultCriteria().checkInDate;
      obj.checkInDate = getDefaultCriteria().checkInDate;
  }
}

  if (!obj.checkOutDate || (obj.checkOutDate && (obj.checkOutDate === ''  || isNaN(Date.parse(obj.checkOutDate))))) {
    if (!obj.checkout_date || (obj.checkout_date  && (obj.checkout_date === ''  || isNaN(Date.parse(obj.checkout_date ))))) {
      obj.checkout_date  = getDefaultCriteria().checkOutDate;
      obj.checkOutDate = getDefaultCriteria().checkOutDate;
  }
}

  if (!obj.adults || (obj.adults && (obj.adults === '' || obj.adults < 1 || isNaN(obj.adults)))) {
    obj.adults = getDefaultCriteria().adults || 1;
  }

  if (!obj.rooms || (obj.rooms && (obj.rooms === '' || obj.rooms < 1 || isNaN(obj.rooms)))) {
    obj.rooms = getDefaultCriteria().rooms || 1;
  }

  if (!obj.children || (obj.children && (obj.children === '' || obj.children < 0 || isNaN(obj.children)))) {
    obj.children = getDefaultCriteria().children || 0;
  }

  if (obj.useWRPoints) {
    obj.useWRPoints = JSON.parse(obj.useWRPoints);
  }

  return obj;
}
let _uriParams = {};

function setSearchParametersFromURL(cb) {
  let decode = decodeURI(window.location.search);

  if (decode) {
    setUriParams(populateObjectFromQueryString(decode));
  }

  if (typeof (cb) === 'function') {
    cb(_uriParams);
  }
}

function setUriParams(obj) {
  if (obj) {
    _uriParams = obj;
  }
}

function getQueryParameter(key) {
  if($.isEmptyObject(_uriParams)){
    setSearchParametersFromURL();
  }
  if (key in _uriParams) {
    return _uriParams[key];
  }
}

function getParameterByName(name, url) {
  if (!url) url = getHrefESApiEncoded();
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#?]*)|&|#|$)');
  var results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';

  return DOMPurify.sanitize(decodeURIComponent(results[2].replace(/\+/g, ' ')));
}

function getUrlQuery(url) {
  let result = {};
  url = url || location.search || location.hash.split('?')[1] || '';
  if (url) {
    let qs = url.substring(url.indexOf('?') + 1).split('&');
    for (let i = 0, result = {}; i < qs.length; i++) {
      qs[i] = qs[i].split('=');
      result[qs[i][0]] = decodeURIComponent(qs[i][1]);
    }
    return result;
  }

  return null;
}

function checkVulnerableUrl(url) {
  let origin = window.location.origin;
  if (url && url.indexOf(origin) == 0) {
    return url;
  } else {
    return null;
  }
}

function removeEndingAmp(url) {
  if (url && url.substring(url.length - 1, url.length) === '&') {
    return url.substring(0, url.length - 1);
  }
  return url;
}

function removeQuery(a_name) {
  if ((a_name != '') && (a_name != null) && (a_name != undefined)) {
    a_name = a_name.split('?')[0];
    a_name = a_name.split('#')[0];
    a_name = a_name.split('&')[0];
  }
  return a_name;
}

function removeProtocol(url) {
  return url.replace('https://', '').replace('http://', '');
}

// COOKIE HANDLING
class Cookie {
  constructor() {
    this.CIDCOOKIE = ACCESS_TOKEN;
  }
  createCookie(name, value, days) {
    let expires = '';
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toGMTString();
    }

    document.cookie = name + '=' + value + expires + '; path=/; secure';
  }
  getCookie(cookieName) {
    // Only bother populating cookies object if needed.
    if ($.isEmptyObject(window._cookies)) {
      window._cookies = [];
      let cookieArray = document.cookie.split('; ');
      for (let i = 0; i < cookieArray.length; i++) {
        let tmp = cookieArray[i].split('=');
        window._cookies[tmp[0]] = tmp[1];
      }
    }
    return window._cookies[cookieName];
  }
  readCookie(name) {
    let nameEQ = name + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  eraseCookie(name) {
    this.createCookie(name, '', -1);
  }
  cidExists() {
    return this.readCookie(this.CIDCOOKIE) !== null;
  }
  cidExpired() {
    let cidExp = new Date(this.readCookie('expiresAt'));
    return new Date() > cidExp;
  }
  cidAuthenticated() {
    return this.readCookie(WR_AUTHENTICATED);
  }
  sessionExists() {
    return localStorage.getItem(SESSION_PROFILE) !== null;
  }
  getSession() {
    return JSON.parse(localStorage.getItem(SESSION_PROFILE));
  }
  tokenExists() {
    return this.readCookie('WHG_REFRESH') !== null;
  }
  getDeviceTypeFromCookie() {
    let deviceType = this.readCookie('device_type') || 'desktop';
    return (deviceType == 'mobile') ? 'mobileweb' : deviceType;
  }
}

let CookieHandler = new Cookie();
let currencyRate = {};
if(window.wyndham){
  if((window.wyndham.configs['top-currencies'] && window.wyndham.configs['full-currencies'])){
    currencyRate = JSON.parse(DOMPurify.sanitize(localStorage.getItem(currencyConverterCookieName)) || '{}');
  } else{
    currencyRate = {};
  }
}

// SERVICE CALL OBJECT AND HANDLER

let _serviceBasePath = '/BWSServices/',
  _wrServiceBasePath = '/WHGServices/',
  serviceUrlObject = {
    getProfileBase: _wrServiceBasePath + 'loyalty/V4/member/profile?includeProfile=true',
    getProfile: _wrServiceBasePath + 'loyalty/V4/member/profile?includeAddresses=true&includeCurrencies=true&includeAliases=true&includeEmails=true&includePhones=true&includePreferences=true&includePaymentCardAccounts=true&includeProfile=true',
    getLimitedProfile: _wrServiceBasePath + 'loyalty/member/recognizedProfile',
    getSearchUrl: _serviceBasePath + 'services/search-criteria/getSearchUrl',
    intlLocations: _serviceBasePath + 'services/search/getLocationsByLanguage',
    retrieveReservation: _serviceBasePath + 'services/booking/retrieveReservation',
    authUser: _wrServiceBasePath + 'loyalty/member/authenticate',
    getSecurityQuestions: _wrServiceBasePath + 'loyalty/member/securityQuestionsRetrieve',
    setSecurityQuestions: _wrServiceBasePath + 'loyalty/member/securityQuestionsSetup',
    getVerifyQuestion: _wrServiceBasePath + 'loyalty/member/twoFormVerificationRetrieve',
    verifyQuestion: _wrServiceBasePath + 'loyalty/member/twoFormVerificationSubmit',
    logout: _wrServiceBasePath + 'loyalty/member/logout',
    enroll: _wrServiceBasePath + 'loyalty/V4/member/enroll',
    onlineAccountSetup: _wrServiceBasePath + 'loyalty/V3/member/onlineAccountSetup',
    validateOtp: _wrServiceBasePath + 'loyalty/V3/member/validateOtp',
    mfa: _wrServiceBasePath + 'loyalty/V3/member/mfa',
    mfaAssociate: _wrServiceBasePath + 'loyalty/V3/member/mfaAssociate',
    verifyEmail: _wrServiceBasePath + 'loyalty/V3/member/verifyEmail',
    overview: '/BWSServices/services/search/property/search',
    rates: '/BWSServices/services/hotels/property-availability',
    roomsRates: '/BWSServices/services/hotels/availability/getRoomsAndRates',
    detailRetrieve: _wrServiceBasePath + 'hotel/availability/detailRetrieve',
    getRoomImage: '/bin/propertyRoomImage.json',
    myCheckToken: _wrServiceBasePath + 'loyalty/V4/member/mycheckWidgetToken',
    confirmReservation: '/BWSServices/services/booking/confirmReservation',
    modifyReservation: '/BWSServices/services/booking/modifyReservation',
    cancelReservation: _serviceBasePath + 'services/booking/cancelReservation',
    regionSearch: _serviceBasePath + 'services/search/searchByRegion',
    brandPropertyPageListing: '/bin/propertypages.json',
    brandCityPageListing: '/bin/citypages.json',
    avCalendar: _wrServiceBasePath + 'hotel/availability/calendarRetrieve',
    autorecommend: '/bin/autorecommend.json',
    allProperties: _serviceBasePath + 'services/search/properties',
    doNotSell: '/bin/wyn/recaptcha',
    getPropertyImage: '/bin/getSearchResults.json',
    currencyRetrieve: '/BWSServices/services/property/currencyRetrieve',
    getPackages: '/BWSServices/services/hotels/packages',
    getPromotions: '/WHGServices/loyalty/V4/member/promotions',
    myFavourite: _wrServiceBasePath + 'loyalty/V4/member/preferences',
    mySearchFavourite: _serviceBasePath + 'services/search/property/collection-hotel',
    dualBrandingAPI: '/bin/dualbrandlogoServlet.json',
  };

function getServiceUrl(key) {
  return serviceUrlObject[key] || null;
}

function callService(url, params, type, headers) {
  let service = getServiceUrl(url);
  url = service || url;

  if (_isNull(type)) {
    type = 'GET';
  }

  let df = $.Deferred();

  $.ajax({
    url: url,
    data: params,
    type: type,
    headers: headers || {},
    cache: url.match(/setCriteria/) ? false : undefined,
    success: function(res) {
      df.resolve(res);
    },
    error: function(res) {
      document.dispatchEvent(
        new CustomEvent('brands-error', {
          detail: res
        })
      );
      df.resolve(res);
    }
  });

  return df.promise();
}

function updateLogo(dualBrandLogo) {
  // Select the <img> element inside the <div> with class navbar__logo-container
  let dualBrandLogoObjAvailable = Object.keys(dualBrandLogo).length > 0;
  // const imgElement = document.querySelectorAll('.navbar__logo-container img');
  const imgWrapperAll = document.querySelectorAll('.navbar__logo-container');

  // add href link to the image anchor
  imgWrapperAll.forEach((imgWrapper)=>{
  const imgElement = imgWrapper.querySelector('.navbar__logo-container img');
  const anchor = imgElement && imgElement.closest('a');
  if (imgElement && dualBrandLogoObjAvailable && anchor) {
    imgWrapper && imgWrapper.classList.add('dual-branding-logo');
    if (dualBrandLogo.duallogoLink && dualBrandLogo.duallogoLink.startsWith('/content')) {
      dualBrandLogo.duallogoLink = dualBrandLogo.duallogoLink + '.html';
      anchor.href = dualBrandLogo.duallogoLink;
    } else {
      anchor.href = dualBrandLogo.duallogoLink || anchor.href;
    }
    // Update the attributes of the <img> element if it exists
    imgElement.src = dualBrandLogo.duallogoColorPath || imgElement.src;
    imgElement.setAttribute('data-white', dualBrandLogo.duallogoWhitePath || imgElement.getAttribute('data-white'));
    imgElement.setAttribute('data-color', dualBrandLogo.duallogoColorPath || imgElement.getAttribute('data-color'));
    imgElement.alt = dualBrandLogo.duallogoAltText || imgElement.alt;
  }
  });
}

// Function to extract propertyInfo and make the AJAX call
function fetchDualBrandLogo() {
  // Extract values from window.propertyInfo
  let { siteID, propertyBrand } = window.propertyInfo;
  const locale = window._LOCALE_;
  if (!(siteID && propertyBrand)) {
    siteID = window.overview_propertyId;
    propertyBrand = window.brand_id;
  }
  // Form the request parameter
  if (locale && siteID && propertyBrand) {
    const params = {
      items: `${locale}:${siteID}:${propertyBrand}`,
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    callService('dualBrandingAPI', params, "GET", headers).then((response) => {
      if (response && response.dualBrandLogo) {
        const dualBrandLogo = response.dualBrandLogo;
        updateLogo(dualBrandLogo);
        const dualBrandLogoSession = {...dualBrandLogo, propId: window.overview_propertyId && window.overview_propertyId };
        sessionStorage.setItem('dualImgAPIResponse', JSON.stringify(dualBrandLogoSession));
      }
    });
}
}

function getTripAdvisorIFrame(propertyId){
  const taIframe =  `<iframe width="140px" height="60px" scrolling="no" style="border: none; overflow: hidden;" src="https://www.tripadvisor.com/WidgetEmbed-cdsLicensedRating?locationId=${propertyId}&partnerId=699BC9B0A50349A389EF1084ECB2CA46&display=true"></iframe>`;
  return (DOMPurify.sanitize(taIframe,{
    ALLOWED_TAGS: ['iframe'],
    ALLOWED_ATTR: ['width', 'height', 'scrolling', 'style', 'src']
}));
}

// Get the value of a hidden input field by its ID.
function getHiddenFieldValueForQuickView(elementId) {
  // Retrieve the element by ID
  const element = document.getElementById(elementId);
  // Check if the element exists and is a input
  if (element && element.tagName === 'INPUT') {
      return element.value;
  }
  return null;
}

function checkSessionForDualBrandImage() {
  try {
    const dualImg = sessionStorage.getItem('dualImgAPIResponse');
    const dualImgSession = dualImg && JSON.parse(dualImg);
    if (dualImgSession && (window.overview_propertyId === dualImgSession.propId)) {
      // if we have session data for the propId then directly call updateLogo else call APi
      updateLogo(dualImgSession);
    } else {
      fetchDualBrandLogo();
    }
  } catch (error) {
    console.log('Error from fetching the dual brand img');
  }
}

// TO BE CONSUMED ONLY FOR MY FAVOURITS HOTELS ALONGSIDE RECENT SEARCH COMPONENT
async function manageSearchfavourite(propertyIds) {
  // method: 'POST',
  const query = `
        query Query($searchParameters: SearchParameters) {
          searchProperties(searchParameters: $searchParameters) {
            properties {
              propertyId
              name
              uniqueUrl
            }
          }
        }
        `;
        const variables = {
          searchParameters: {
            language: _LOCALE_,
            limit: 50,
            offset: 0,
            propertyIds: propertyIds
          }
        };
        const payload =  {
          query: query,
          variables: variables
        };
      const headers = {
        'Content-Type': 'application/json',
      };
  let res = await callService('mySearchFavourite', JSON.stringify(payload), 'POST', headers);
  return res;
}
async function manageFavoriteHotels(action, data, type) {
  const refreshToken = CookieHandler.readCookie('refresh_token');
  const accessToken = CookieHandler.readCookie('access_token');
  const timestamp = Date.now();
  const user = JSON.parse(localStorage.getItem('OT_WHG_SESSION'));
  const Cookie = `refresh_token=${refreshToken};access_token=${accessToken};loglocale={"timestamp":${timestamp},"serviceVersion":"1.0","pageHashCode":"/WHGServices/loyalty/member/verifyEmail?language=en-us","language":"en-us","channel":"responsive","seed":"DE47431A-BC2F-429E-8785-EA881682D951"};`;
  const headers = {
    'Content-Type': 'application/json',
    Cookie: Cookie,
  };
  let payload = {};
  if(action === 'GET') {
    payload = {
      uniqueId: user.membershipId,
      type: '',
      action: action,
    };
  } else if(data != null) {
    payload = {
      uniqueId: user.membershipId,
      type: type || 'FavoriteHotels',
      action: action,
      data: data,
    };
  }
  let res = await callService('myFavourite', JSON.stringify(payload), 'POST', headers);
  return res;

}
async function fetchOtherAmenities(payload) {
  const url = '/bin/getStarAmenities';
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
 
    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText);
    }
 
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
  }
}

// UNAP HANDLER - URL, NAME, ADDRESS, PHONE TRANSLATIONS
// FOR INTERNATIONAL SITES

const _unapData = {};

function setUNAP(obj) {
  $.extend(true, _unapData, obj);

  eventHandlerInst.send(eventHandlerInst.UNAP.updated);
}
function getUNAP(key) {
  if (_isNotNull(key)) {
    return extractDataFromObject(key, _unapData);
  } else {
    return _unapData;
  }
}
function getName() {
  return _unapData.name || '';
}
function getCity() {
  return _unapData.city || '';
}
function getAddress() {
  if(property_country_code && (property_country_code === 'CA' || property_country_code === 'US' || property_country_code === 'MX')){
     return _unapData.address + ', ' + _unapData.city + ', ' + (_unapData.state ? _unapData.state : _unapData.country) + ' ' + _unapData.postalCode;
  } else {
      return _unapData.address + ', ' + _unapData.city + (_unapData.state ? ', ' + _unapData.state : '') + (_unapData.country ? ', ' + _unapData.country : '') + ' ' + _unapData.postalCode;
  }
}

function getAddressWithoutProvinceCountry() {
  return _unapData.address + ', ' + _unapData.city + ' ' + _unapData.postalCode;
}

function getDefaultSearchRadius(size) {
  let isMilesLocation = (CookieHandler.readCookie('country_code') && CookieHandler.readCookie('country_code').toString() === 'US') ? true : false;
  if (size === 'medium') {
    return (isMilesLocation) ? _defaultMediumSearchRadius : _defaultMediumSearchRadiusKm;
  } else if (size === 'small') {
    return (isMilesLocation) ? _defaultSmallSearchRadius : _defaultSmallSearchRadiusKm;
  } else {
    return (isMilesLocation) ? _defaultSearchRadius : _defaultSearchRadiusKm;
  }

}
function convertToDate(date) {
  return (
    date.constructor === Date ? date :
      date.constructor === Array ? new Date(date[0],date[1],date[2]) :
        date.constructor === Number ? new Date(date) :
          date.constructor === String ? new Date(date) :
            typeof date === 'object' ? new Date(date.year,date.month,date.date) :
              NaN
  );
}

function dateInRange(todayDate, startDate, endDate) {
  return (
    isFinite(todayDate = convertToDate(todayDate).valueOf()) &&
    isFinite(startDate = convertToDate(startDate).valueOf()) &&
    isFinite(endDate = convertToDate(endDate).valueOf()) ?
      startDate <= todayDate && todayDate <= endDate :
      NaN
  );
}
function isNotificationEnable(ele) {
  let startDateVal = (ele.is('[data-startDate]') && ele.attr("data-startDate")) ? ele.attr("data-startDate") : '',
    endDateVal = (ele.is('[data-endDate]') && ele.attr("data-endDate")) ? ele.attr("data-endDate") : '',
    todayDate = new Date(),
    startDate = startDateVal ? new Date(startDateVal) : '',
    endDate = endDateVal ? new Date(endDateVal) : '',
    startDateUTC,
    endDateUTC;
    if(startDate && endDate) {
      endDate.setMilliseconds(0);
      endDate.setSeconds(59);
      endDate.setMinutes(59);
      endDate.setHours(23);
      let todayDateUTC = todayDate.toUTCString();
        startDateUTC = startDate.toUTCString();
        endDateUTC = endDate.toUTCString();
        if(dateInRange(todayDateUTC, startDateUTC, endDateUTC)) {
          return true;
        } else {
          return false;
        }
     } else {
       return true;
     }
}
function enableNewRenovatedPriceTag(startDateVal, endDateVal) {
  let todayDate = new Date(),
    startDate = startDateVal ? new Date(startDateVal) : '',
    endDate = endDateVal ? new Date(endDateVal) : '',
    startDateUTC,
    endDateUTC;

  if(startDate && endDate) {
    endDate.setMilliseconds(0);
    endDate.setSeconds(59);
    endDate.setMinutes(59);
    endDate.setHours(23);
    let todayDateUTC = todayDate.toUTCString();
    startDateUTC = startDate.toUTCString();
    endDateUTC = endDate.toUTCString();
     return dateInRange(todayDateUTC, startDateUTC, endDateUTC);
  } else {
    return false;
  }
}

function allyPrevNextDatePicker() {
  setTimeout(function() {
    if($('.ui-datepicker-prev').length) {
      if(!$('.ui-datepicker-prev').hasClass('ui-state-disabled')) {
        $('.ui-datepicker-prev').attr({
          "href": "#",
          "tabindex": "0",
          "onclick": "event.preventDefault()"
        });
        $('.ui-datepicker-prev').keydown( function(e) {
          if(e.keyCode === 13 || e.keyCode === 32) {
            e.preventDefault();
            $(e.currentTarget).click();
          }
        });
      } else {
        $('.ui-datepicker-prev').removeAttr('href tabindex onclick');
      }
    }
    if($('.ui-datepicker-next').length) {
      $('.ui-datepicker-next').attr({
        "href": "#",
        "tabindex": "0",
        "onclick": "event.preventDefault()"
      });
      $('.ui-datepicker-next').keydown( function(e) {
        if(e.keyCode === 13 || e.keyCode === 32) {
          e.preventDefault();
          $(e.currentTarget).click();
        }
      });
    }
  }, 500);
}

function hasPolicyFlag(policyFlag) {
  return policyFlag && policyFlag.toUpperCase() === 'N';
}

class EventHandler {
  constructor() {
    this.header = {
      load: 'header.load'
    };
    this.criteria = {
      init: 'WHG.Tablet.global.criteria.init',
      updated: 'WHG.Tablet.global.critieria.updated',
      success: 'WHG.Tablet.global.success.getCriteria'
    },
    this.profile = {
      fetched: 'WHG.Event.profile.fetched',
      signedIn: 'WHG.Event.profile.signedIn',
      signedOut: 'WHG.Event.profile.signedOut',
      oktaSignOut: 'WHG.Event.profile.oktaSignOut'
    };
    this.fullProfile = {
      fetched: 'WHG.Event.fullProfile.fetched'
    };
    this.getLocation = {
      start: 'WHG.Tablet.global.getLocation.fetching',
      success: 'WHG.Tablet.global.getLocation.success',
      error: 'WHG.Tablet.global.getLocation.error'
    };
    this.getLocByLanguage = {
      error: 'WHG.Tablet.global.getLocByLanguage.error'
    };
    this.retrieveReservation = {
      start: 'WHG.Tablet.global.retrieveReservation.fetching',
      success: 'WHG.Tablet.global.retrieveReservation.success',
      error: 'WHG.Tablet.global.retrieveReservation.error'
    };
    this.serviceCall = {
      start: 'serviceCall.pageLoad.start',
      end: 'serviceCall.pageLoad.end'
    };
    this.BookingBarLoad = 'BookingBar.loadComplete';
    this.data = {
      updated: 'data:updated'
    };
    this.getSearchUrl = {
      start: 'WHG.Tablet.global.fetching.getSearchUrl',
      success: 'WHG.Tablet.global.success.getSearchUrl',
      error: 'WHG.Tablet.global.error.getSearchUrl'
    };
    this.rates = {
      propertyAvailability: {
        start: 'WHG.property.rates.propertyAvailability.start',
        success: 'WHG.property.rates.propertyAvailability.success',
        error: 'WHG.property.rates.propertyAvailability.error',
        ratesAvailable: 'WHG.property.rates.propertyAvailability.ratesAvailable',
        ratesUnavailable: 'WHG.property.rates.propertyAvailability.ratesUnavailable'
      }
    };
    this.property = {
      search: {
        start: 'WHG.property.overview.search.start',
        success: 'WHG.property.overview.search.success',
        error: 'WHG.property.overview.search.error'
      }
    };
    this.modal = {
      show: 'modal.show',
      hide: 'modal.hide'
    };
    this.UNAP = {
      updated: 'unap:updated'
    };
    this.dates = {
      updated: 'dates:updated'
    };
    this.lazyLoad = {
      ready: 'WHG.lazyLoad.ready',
      rebind: 'WHG.lazyLoad.rebind'
    };
    this.srpLazyLoad = {
      init: 'srp.lazyloading.images'
    };
  }
  one(eventName, callback, $element) {
    if (!($element) || !($element.length > 0)) {
      $element = $('html');
    }

    $element.one(eventName, callback);

    return this;
  }
  on(eventName, callback, $element) {
    if (!($element) || !($element.length > 0)) {
      $element = $('html');
    }

    $element.on(eventName, callback);

    return this;
  }
  off(eventName, callback, $element) {
    if (!($element) || !($element.length > 0)) {
      $element = $('html');
    }

    if (typeof (callback) === 'function') {
      $element.off(eventName, callback);
    } else {
      $element.off(eventName);
    }

    return this;
  }
  send(eventName, $element, data) {
    if (!($element) || !($element.length > 0)) {
      $element = $('html');
    }

    $element.trigger(eventName, data);

    return this;
  }
  triggerEvent(eventType, detailData) {
    if (detailData) {
      document.dispatchEvent(
        new CustomEvent(eventType, {
          detail: detailData
        })
      );
      document.body.dispatchEvent(
        new CustomEvent(eventType, {
          detail: detailData
        })
      );
    } else {
      document.dispatchEvent(new CustomEvent(eventType));
      document.body.dispatchEvent(new CustomEvent(eventType));
    }
  }
}



class AccessPage {

  handleAccess() {
    let $pageContent = $('.page-content'),
        $bookingPage = $('.page.booking-page'),
        $packagePage = $('.page.packages-page');
    if ($pageContent.hasClass('reservation-confirmed')) {
      if (sessionStorage.getItem('lcp') || !isPublish) {
        sessionStorage.removeItem('lcp');
        sessionStorage.removeItem('lbp');
        $pageContent.removeClass('hide');
        this.forceToReload();
      } else {
        location.href = _isNotNull(window.brand) ? window.brand : '/';
      }
    }
    if ($bookingPage.length > 0) {
      if (sessionStorage.getItem('lbp') || !isPublish) {
        $bookingPage.removeClass('hide');
        this.forceToReload();
      } else {
        location.href = _isNotNull(window.brand) ? window.brand : '/';
      }
    }
    if ($packagePage.length > 0) {
      if (sessionStorage.getItem('lbp') || !isPublish) {
        $packagePage.removeClass('hide');
        this.forceToReload();
      } else {
        //location.href = _isNotNull(window.brand) ? window.brand : '/';
      }
    }

  }

  forceToReload() {
    let isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (isIOS) {
      window.onpageshow = (event) => {
        if (event.persisted) {
          document.body.style.display = "none";
          location.reload();
        }
      };
    }
  }
}

let accessPage = new AccessPage();
accessPage.handleAccess();
let eventHandlerInst = new EventHandler();

class RetailBannerUtils {
  slideDownRetailBanner() {
    eventHandlerInst.send('retailbanner:slide-down');
  }
}

let retailBannerUtilsInst = new RetailBannerUtils();

function isValidOktaPassword(password) {
  let requirementCount = 0;
  const hasSpecialCharacters = password.match(/^(?=.*[!@#$%^&]).*$/);
  const hasUppercase = password.match(/^(?=.*[A-Z]).*$/);
  const hasLowercase = password.match(/^(?=.*[a-z]).*$/);
  const hasNumber = password.match(/^(?=.*[0-9]).*$/);
  [hasSpecialCharacters, hasUppercase, hasLowercase, hasNumber].forEach(req => {
    if (req) {
      requirementCount++;
    }
  });
  const hasCharacters =  password.length >= 8;
  return hasCharacters && (requirementCount >= 3);
}

function mapTierCode(code) {
  // Mixed challenger tier code for accelerator-level
  const tierCodeMap = {
    'B': 'Blue',
    'G': 'Gold',
    'GT': 'Gold',
    'P': 'Platinum',
    'PT': 'Platinum',
    'D': 'Diamond',
    'DT': 'Diamond',
    'T': 'Titanium',
    'TT': 'Titanium'
  };
  return tierCodeMap[code];
}

function waitForElementToExist(selector) {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      subtree: true,
      childList: true,
    });
  });
}

function mapBusinessTravelerType(code) {
  const map = {
    BL: 'Business and Leisure',
    GMP: 'Group & Meeting Planner',
    TBM: 'Travel Buyer/Manager',
    TA: 'Travel Advisor',
    SMB: 'Small or Midsize Business'
  };
  return map[code] ? map[code] : '';
}

function mapBusinessTravelerString(string) {
  const map = {
    'Business and Leisure': 'BL',
    'Group & Meeting Planner': 'GMP',
    'Travel Buyer/Manager': 'TBM',
    'Travel Advisor': 'TA',
    'Small or Midsize Business': 'SMB'
  };
  return map[string] ? map[string] : '';
}

function mapPhoneTypeCode(code) {
  const map = {
    business: 'Work',
    home: 'Home',
    mobile: 'Mobile',
    fax: 'Fax'
  };
  return map[code] ? map[code] : '';
}

function getJsonObject(str) {
  return str ? typeof str === 'object' ? str : JSON.parse(str) : str;
}
// updated TPD values for Analytis purpose DAI-38757
function getUpdatedTPDTypeValue(value) {
  return value;
/* reverting changes until requirements are finilized
* remove this comment delete the return statement in the top
* update the switch statements (if needed) once the requirements are finalized for DAI-38757

  if(!value || typeof(value) !== 'string') return value;
  let updatedValue = '';
  switch (value.toUpperCase()) {
  case 'CMA':
    updatedValue = 'Daily Rate + Taxes & Fees';
    break;
  case 'TPD':
    updatedValue = 'Daily Rate + Fees';
    break;
  case 'TNF':
    updatedValue = 'Daily Rate';
    break;
  case 'RRT':
    updatedValue = 'Daily Rate';
    break;
  default:
    updatedValue = value;
  }
  return updatedValue;

  */
}

function getLocalStorage(name) {
  var storage = localStorage.getItem(name) || false;
  if (storage && typeof storage === 'string') {
    try {
      storage = JSON.parse(storage);
    } catch (e) {
      return false;
    }
  }
  return storage;
}

function getMobileObjValue(localObj) {
  const mobileObj = {
    isPhApp: false,
    platform: ''
  };
  if (localObj.isPhApp) {
    mobileObj.isPhApp = localObj.isPhApp || false;
    mobileObj.platform = localObj.platform || '';
  }
  return mobileObj;
}

function getMobileObj() {
  const urlParams = new URLSearchParams(window.location.search),
    ftsiInfo = getLocalStorage(FTSI),
    enrollmentInfo = getLocalStorage(ENROLLMENT),
    hasPhAppParams = urlParams.has('platform') && urlParams.has('adobeID'),
    mobileObj = {
      isPhApp: hasPhAppParams || false,
      platform: (hasPhAppParams && urlParams.get('platform')) || ''
    };
  if (hasPhAppParams) {
    return mobileObj;
  }
  if (ftsiInfo) {
    return getMobileObjValue(ftsiInfo);
  }
  if (enrollmentInfo) {
    return getMobileObjValue(enrollmentInfo);
  }
  return mobileObj;
}

function getLogLocale() {
  const cookieString = CookieHandler.getCookie(LOGLOCALE_COOKIE),
    cookie = cookieString ? JSON.parse(cookieString) : null,
    mobileObj = getMobileObj();
  let loglocale;
  if (mobileObj.isPhApp) {
    loglocale = {
      timestamp: Date.now(),
      serviceVersion: '1.0',
      pageHashCode: '',
      language: _LOCALE_,
      channel: 'mobile',
      platform: mobileObj.platform.toLowerCase(),
      seed: cookie && cookie.seed ? cookie.seed : ''
    };
  } else {
    loglocale = {
      seed: cookie && cookie.seed ? cookie.seed : '',
      pageHashCode: pageHash,
      timestamp: Date.now(),
      channel: 'responsive',
      serviceVersion: '1.0',
      language: _LOCALE_
    };
  }
  return loglocale;
}

function getTopLevelPayload() {
  const inputs = document.querySelectorAll('input[type="hidden"]:not(.sort-tab input)');
  const values = {};
  inputs.forEach(input => {
    values[input.id] = input.value || '';
  });
  return values;
}

function getSortTabPayload() {
  const sortTabDivs = document.querySelectorAll('.sort-tab');
  const sortTabs = [];

  sortTabDivs.forEach(div => {
    const inputs = div.querySelectorAll('input[type="hidden"]');
    const sortValues = {};
    inputs.forEach(input => {
      const value = input.value.includes(',') ? input.value.split(',') : input.value;
      sortValues[input.name] = value;
    });
    sortTabs.push(sortValues);
  });

  return sortTabs
  .filter(item => item.sortKey && item.sortOrder)
  .map(item => {
    let sortingKey;
    switch (item.sortKey) {
      case 'country':
        sortingKey = 'hotel.country_code';
        break;
      case 'state':
        sortingKey = 'hotel.state_code';
        break;
      case 'city':
        sortingKey = 'hotel_full_view.city';
        break;
      default:
        sortingKey = '';
    }
    return {
      sortOrder: item.sortOrder,
      sortingKey: sortingKey,
      prioritize: item.priority
    };
  })
  .filter(item => item.sortingKey !== '');
}

function getCollectionCode() {
  let collectionCode = null;
  const element = document.querySelector('#collectionCode');
  if (element && element.value && element.value !== ',') {
    collectionCode = [
      {
        name: element.id,
        value: element.value,
      },
      { name: 'collectionEnabled', value: 'TRUE' },
    ];
  }
  return collectionCode;
}

function getBaselineParameters() {
  const baselineParameters = {};
  const collectionSortPayload = getSortTabPayload();
  const collectionInitPayload = getTopLevelPayload();
  baselineParameters.sortBy = collectionSortPayload;
  if(collectionInitPayload.country) {
    baselineParameters.countryCodes = collectionInitPayload.country.split(',');
  }
  if(collectionInitPayload.state) {
    baselineParameters.stateCodes = collectionInitPayload.state.split(',');
  }
  if(collectionInitPayload.city) {
    baselineParameters.filters = collectionInitPayload.city.split(',').map(cityName => ({name: 'hotel_full_view.city', value: cityName}));
  }
  if (collectionInitPayload.brands) {
    baselineParameters.brandIds = collectionInitPayload.brands.split(',').map(brand => brand.toUpperCase());
  }
  if (collectionInitPayload.brandsTier) {
    baselineParameters.brandTierCode = collectionInitPayload.brandsTier.split(',').map(brandTier => brandTier.toUpperCase());
  }
  if (collectionInitPayload.amenities) {
    baselineParameters.hotelSearchableAmenityCodes =
      collectionInitPayload.amenities.split(',');
  }
  return baselineParameters;
}

function getSearchParameters(searchParameters, filterOptions) {
  if(filterOptions) {
    return {...searchParameters, ...filterOptions};
  }
  const collectionCode = getCollectionCode();
  if(collectionCode) {
    return {...searchParameters, filters: collectionCode};
  }
  const baselineParameters = getBaselineParameters();
  if(baselineParameters) {
    return { ...searchParameters, ...baselineParameters };
  }
  return searchParameters;
}

function getBaselineFilters() {
  const searchParameters = getBaselineParameters();
  searchParameters.cityNames = [];
  if (!searchParameters.countryCodes) {
    searchParameters.countryCodes = [];
  }
  if (!searchParameters.stateCodes) {
    searchParameters.stateCodes = [];
  }
  if (searchParameters.filters) {
    searchParameters.cityFilters = searchParameters.filters.filter(
      (el) => el.name && el.name === 'hotel_full_view.city'
    );
    searchParameters.cityNames = searchParameters.cityFilters.map(el => el.value);
  }
  return searchParameters;
}

export {
  _isNumber,
  _isValidBrand,
  _isValidBrandTier,
  _isWeakTrue,
  _isWeakerTrue,
  _isWeakFalse,
  _isNull,
  _isNotNull,
  convertMonthsToDays,
  getToday,
  getTomorrow,
  getNextDay,
  getNumDays,
  getXDays,
  _isDateType,
  getDateFromDateString,
  formatDateForBWS,
  formatDateForG360,
  formatDateForSnowstorm,
  formatDateForBWSAvailability,
  formatDateForWHGAvailability,
  formatDateForMyCheck,
  formatDateForPrinting,
  formatDateForUplift,
  isPriorDate,
  isToday,
  getCurrencyMapping,
  convertToCurrency,
  getSelectedCurencyCode,
  updateCurrencySelectorCode,
  currencyConverterCookieName,
  currencyRate,
  isFeesInclude,
  uppercaseFirstLetter,
  uppercaseFirstLetterEachWord,
  hyphenToCamel,
  sortObjectList,
  handlePostSubtotal,
  isBarRate,
  isHoldRate,
  isAllInPriceRegion,
  isSMORate,
  isSelectedRate,
  PickSelectedRatePlan,
  isGoFastRate,
  isGoFreeRate,
  isBonusRP,
  isLoyaltyRP,
  isMemberRate,
  formatNumber,
  toMoney,
  formatTimeShow,
  printDollarAmount,
  printDollarAmountonPayment,
  getISOFormatDate,
  escapeRegExp,
  extractDataFromObject,
  removeDiacritics,
  getLocaleUrlToken,
  getHotelLocaleUrlToken,
  getSecureHostedDomainUrl,
  once,
  debounce,
  replaceToken,
  buildLocationDetailsFromProperty,
  getDefaultCriteria,
  updateQueryStringParameter,
  serializeObject,
  isValidNumber,
  shuffle,
  findValueByKey,
  getHeaderHeight,
  getPromoHeight,
  getHeroHeight,
  getBookingBarHeight,
  isMemberAttr,
  isiOS,
  observeSrcMutations,
  isIE,
  getQueryString,
  updateQueryParameter,
  updateSearchParameters,
  setSearchParametersFromURL,
  getQueryParameter,
  getParameterByName,
  getUrlQuery,
  checkVulnerableUrl,
  removeEndingAmp,
  removeQuery,
  removeProtocol,
  populateObjectFromQueryString,
  CookieHandler,
  manageFavoriteHotels,
  getServiceUrl,
  callService,
  setUNAP,
  getUNAP,
  getName,
  getCity,
  getAddress,
  getAddressWithoutProvinceCountry,
  eventHandlerInst as EventHandler,
  retailBannerUtilsInst as RetailBannerUtils,
  getDateFormatFromISO,
  getDefaultSearchRadius,
  convertToDate,
  dateInRange,
  enableNewRenovatedPriceTag,
  allyPrevNextDatePicker,
  hasPolicyFlag,
  isNotificationEnable,
  isWRCCRate,
  sortRoomRatePlans,
  isFlexibleRate,
  isBookMessagingForcedToHide,
  isStrikeThroughRateEnable,
  isBNPLEX,
  getRetailBannerHeight,
  hasRetailBanner,
  isValidOktaPassword,
  mapTierCode,
  waitForElementToExist,
  mapBusinessTravelerType,
  mapBusinessTravelerString,
  mapPhoneTypeCode,
  getHrefESApiEncoded,
  getJsonObject,
  getUpdatedTPDTypeValue,
  isDateInBetween,
  getLogLocale,
  getTopLevelPayload,
  getSortTabPayload,
  getCollectionCode,
  manageSearchfavourite,
  getBaselineParameters,
  getSearchParameters,
  getBaselineFilters,
  fetchOtherAmenities,
  checkSessionForDualBrandImage,
  getTripAdvisorIFrame,
  getHiddenFieldValueForQuickView
};
