
import WrNonParticipatingBrandConfig from '../base/aem-configs/wr-non-participating-brands-config.js';
import {
  exists,
  isDesktopWidth,
  isTabletWidth,
  isMobileWidth,
} from '../base/dom-utils.js';
import {
  EventHandler,
  getRetailBannerHeight,
  _isNotNull,
  _isNumber
} from '../base/utils.js';
import {
  $_PAGE_,
  requestUrls
} from '../base/vars.js';

class GlobalNavigation {
  constructor() {
    if (exists('.navbar')) {
      this.hideWRWhenNotParticipating();
      this.bindDropdowns();
      this.bindDropdownClose();
      this.closeDropDownsOnClickOutside();
      this.hideModalOnScroll();
      this.onclickModal();
      if(isDesktopWidth()){
        this.setInitialBorder();
      }
    }
    if(exists('.wyndham-rewards-content')) {
      $('.wyndham-rewards-content .dropdown-toggle-myacc').on('click', function() {
        $('body').toggleClass('login-dd-open', !$('.wyndham-rewards-content').hasClass('open'));
      });
      $(document).on('click', function(event) {
        // Check if the click was outside of the '.wyndham-rewards-content .sign-in' element
        if (
          !$(event.target).closest('.wyndham-rewards-content .sign-in button, .wyndham-rewards-content .sign-in a')
            .length
        ) {
          $('body').removeClass('login-dd-open');
        }
      });
    }
    this.setupViewForRetailBanner();
    this.bindOpenPanels();
    this.bindClosePanels();

    this.name = 'language-list';
    this.$component = $('.' + this.name);
    if (this.$component.length > 0) {
      this.triggerLanguageSelect();
    }

  }

  inViewportHeight($el) {
    var elH = $el.outerHeight(),
      H = $(window).height(),
      r = $el[0].getBoundingClientRect(),
      t = r.top,
      b = r.bottom;
    return Math.max(0, t > 0 ? Math.min(elH, H - t) : Math.min(b, H));
  }

  hideWRWhenNotParticipating() {
    if (!WrNonParticipatingBrandConfig.isCurrentBrandParticipatingToWR()) {
      $('.navbar__wyndham-rewards-container').remove();
      $('.my-account-nav').remove();
      $('.top-nav-item.account').remove();

      if ($('.language-selector').length) {
        $('.navbar__main-links').addClass('wr-non-participation')
          .removeClass('col-md-15')
          .addClass('col-md-20');
      }
    } else if(['av','lt'].includes(window.brand_id)){
      $('.navbar__wyndham-rewards-container').remove();
      $('.my-account-nav').remove();
      $('.top-nav-item.account').remove();
      if ($('.language-selector').length) {
        $('.navbar__main-links').addClass('wr-non-participation')
          .removeClass('col-md-15')
          .addClass('col-md-20');
      }
    }
  }

  bindDropdowns() {
    $(document).on('click', '.navbar', (e) => {
      if(!$(e.target).parents().hasClass('dropdown')){
        this.openDropdown(e);
      }
   });
    $(document).on('keyup', '.navbar', (e) => {
      if(!$(e.target).parents().hasClass('booking-bar-form')){
        if (e.keyCode == 13 || e.keyCode == 32) {
          this.openDropdown(e);
        }
      }
    });
    $(document).on('keyup', (e) => {
      if (e.keyCode == 27) {
        this.closeDropdowns();
      }
    });
  }
  openDropdown(e) {
    if (isDesktopWidth()) {
      let $currentDropdownOpen = $(e.target).hasClass('open') || $(e.target).closest('.top-nav-item').hasClass('open');
      $('.top-nav-item').removeClass('open');
      $('.desktop-nav-item').removeClass('open');
      $('.navbar-collapse .dropdown-wrapper').css('top',$_PAGE_.is('.homepage') ? $('#pageHeader').outerHeight() + 1 : $('#pageHeader').outerHeight());
      $('.dropdown[data-dropdown=reservations],.dropdown[data-dropdown=book]').trigger('wr:dropdown:closed');
      $('.dropdown-overlay-desktop').remove();
      let id = $(e.target).closest('.top-nav-item')[0].id || $(e.target).closest('.top-nav-item')[0].id || $(e.target).parent().parent()[0].id;
      if (!$currentDropdownOpen && id  && id !== 'headerLinkContainer' && id !== 'mainNav' ) {
        $(`#${id}`).addClass('open');
        $(`#${id}`).parent().addClass('open');
        $('header.old-navigation-hide').addClass('dropdown-opened');
        if (id === 'my-account') {
          $('.my-account-nav-container').addClass('my-account-nav-container-active');
        }
        if (id == 'book'){
          $('.dropdown[data-dropdown=reservations],.dropdown[data-dropdown=book]').trigger('wr:dropdown:open');
        }
        $('header.old-navigation-hide').after('<div id="overlay-container" class="dropdown-overlay-desktop"></div>');
      } else if ($currentDropdownOpen) {
        $(`#${id}`).removeClass('open');
        $(`#${id}`).parent().removeClass('open');
        $('header.old-navigation-hide').removeClass('dropdown-opened');

        if (id != 'book'){
          $('.dropdown[data-dropdown=reservations],.dropdown[data-dropdown=book]').trigger('wr:dropdown:closed');
        }
        $('.my-account-nav-container').removeClass('my-account-nav-container-active');
        $('.dropdown-overlay-desktop').remove();
      } else if(!$(e.target).parents().hasClass('dropdown-wrapper')) {
        this.closeDropdowns(e);
        $('header.old-navigation-hide').removeClass('dropdown-opened');
        if (id != 'book'){
          $('.dropdown[data-dropdown=reservations],.dropdown[data-dropdown=book]').trigger('wr:dropdown:closed');
        }
        $('.my-account-nav-container').toggleClass('my-account-nav-container-active');
      }
    }
  }
  closeDropdowns(e) {
    $('header.old-navigation-hide').removeClass('dropdown-opened');
    $('div.desktop-nav-item').removeClass('open');
    $('div.top-nav-item').removeClass('open');
    $('#overlay-container').remove();
    $('.dropdown-overlay-desktop').remove();
    $('.my-account-nav-container').removeClass('my-account-nav-container-active');
    $('.dropdown[data-dropdown=reservations],.dropdown[data-dropdown=book]').trigger('wr:dropdown:closed');
  }
  bindDropdownClose() {
    let _disableClose = false;
    $('header').on('dropdown:open', () => {
      _disableClose = true;
    })
      .on('dropdown:closed', () => {
        _disableClose = false;
      });

    $(document).on('click', '.dropdown-overlay-desktop, .dropdown-wrapper', (e) => {
     
      
      e.stopPropagation();
      if (_disableClose) {
        return;
      }

      if (isDesktopWidth() && !$(e.target).parents().hasClass('dropdown-wrapper')) {
        this.closeDropdowns(e);
      }
    });

    $(document).on('forceCloseDropdowns', (e) => {
      e.stopPropagation();

      if (isDesktopWidth()) {
        this.closeDropdowns(e);
      }
    });
  }

  getMobileOrTabletMenuMarginTop(){
    let dropdownMargin;
        if ($_PAGE_.is('.homepage-template') && exists('#smartbanner')){
          dropdownMargin = $('#smartbanner').height() + getRetailBannerHeight();
        }else{
          dropdownMargin = getRetailBannerHeight();
        }
      return dropdownMargin;
  }

  setupViewForRetailBanner() {
    if (isTabletWidth() || isMobileWidth()) {     
      let dropdownMargin = this.getMobileOrTabletMenuMarginTop();
      EventHandler.on('retailbanner:loaded', () => {
        dropdownMargin = this.getMobileOrTabletMenuMarginTop();
        $('header.mobile-nav .dropdown').css('marginTop', dropdownMargin);
      });
    }
  }

  closeDropDownsOnClickOutside() {
    $(document).on('click', (e) => {
        if ($('header.old-navigation-hide').hasClass('dropdown-opened') && !$(e.target).parents().find('top-nav-item ') && ($(e.target).parents()[0].id === '' || $(e.target).parent()[0].id === undefined || $(e.target).parent()[0].id === 'headerLinkContainer')) {
          this.closeDropdownsOnClickOustide(e);
        }
    });
  }

  closeDropdownsOnClickOustide(e) {
    let $currentDropdownOpen = $('div.desktop-nav-item').hasClass('open');
    if ($currentDropdownOpen) {
      $('div.desktop-nav-item').removeClass('open');
    }
    $('header.old-navigation-hide').removeClass('dropdown-opened');
    $('#overlay-container').remove();
  }

  onclickModal(e) {
    $(document).on('click', '.container .dropdown-wrapper', (e) => {
      if (isDesktopWidth() && !$(e.target).parents().hasClass('dropdown')) {
        $('.dropdown-overlay-desktop').remove();
      }
    });
    
  }


  hideModalOnScroll(e) {
    if (isDesktopWidth()) {
      let prevScrollpos = 0;
      window.onscroll = () => {
        let currentScrollPos = window.pageYOffset || document.documentElement.scrollTop;
        if (currentScrollPos > prevScrollpos) {
          if ($('.my-account-top-nav-after-sign').length <= 0 && ($('.booking-bar .stick').length > 0 || $('.extended-hero-sticky-bb').length > 0)) {
            $('.my-account-nav').removeClass('my-account-nav-light');
            $('.my-account-nav').removeClass('my-account-nav-dark');
            $('.desktop-language-dropdown').removeClass('language-nav-container-dark');
            $('.desktop-language-dropdown').removeClass('language-nav-container-light');
          } else {
            this.setInitialBorder();
          }
        }else{
          this.setInitialBorder();
        }
        prevScrollpos = currentScrollPos <= 0 ? 0 : currentScrollPos;
      };
    }
  }


  setInitialBorder() {
    let headerColor = $('#pageHeader').css('background-color'),
      headerColorFixed = (headerColor === 'transparent') ? 'rgba(0,0,0,0)' : headerColor,
      m = headerColorFixed.match(/rgba{0,1}\((\d+),.{0,1}(\d+),.{0,1}(\d+),{0,1}.{0,1}(\d*)\)/i);

    $('.navbar-collapse .dropdown-wrapper').css('top',$('#pageHeader').outerHeight());

    if (m.length === 5) {
      let red = m[1] * 1,
        green = m[2] * 1,
        blue = m[3] * 1,
        alpha = (_isNotNull(m[4]) && _isNumber(m[4] * 1)) ? (m[4] * 1) : 1,
        max = 255 * 255 * 255;

      if ((red * green * blue * alpha) < (max / 2)) {
        if($_PAGE_.is('.uu-property')){
          // light #c5c5c5 for upscale property 
          $('.my-account-nav').removeClass('my-account-nav-light');
          $('.my-account-nav').addClass('my-account-nav-dark');
          $('.desktop-language-dropdown').removeClass('language-nav-container-light');
          $('.desktop-language-dropdown').addClass('language-nav-container-dark');
        }else{
          // dark #555 for dark background
          $('.my-account-nav').removeClass('my-account-nav-dark');
          $('.my-account-nav').addClass('my-account-nav-light');
          $('.desktop-language-dropdown').removeClass('language-nav-container-dark');
          $('.desktop-language-dropdown').addClass('language-nav-container-light');
          $('#pageHeader').removeClass('home-header-buttom-dark');
          $('#pageHeader').addClass('home-header-buttom-light');
        }
      }else{
        // light #c5c5c5 for light background
        $('.my-account-nav').removeClass('my-account-nav-light');
        $('.my-account-nav').addClass('my-account-nav-dark');
        $('.desktop-language-dropdown').removeClass('language-nav-container-light');
        $('.desktop-language-dropdown').addClass('language-nav-container-dark');
        $('#pageHeader').removeClass('home-header-buttom-light');
        $('#pageHeader').addClass('home-header-buttom-dark');
      }
    }
  }

  bindOpenPanels() {
    $(document).on('click', 'button.header-mobile-button', (e) => {
      let panel = $(e.currentTarget).data('panel');
      if (!isDesktopWidth() && panel) {
        $(e.currentTarget).parents('.dropdown[data-dropdown="main"]')
          .find('.header-mobile-panel[data-panel=' + panel + ']')
          .addClass('panel-current')
          .attr('aria-expanded', true);
        $('header.mobile-nav .dropdown[data-dropdown="main"]').addClass('panel-open');
        $('header.mobile-nav .panel-right').one('webkitTransitionEnd otransitionend msTransitionEnd transitionend', (e) => {
          $('header.mobile-nav .panel-main').hide();
        });
      }
    });
  }

  bindClosePanels() {
    $(document).on('click', '.header-mobile-panel-top .back', (e) => {
      if (!isDesktopWidth()) {
        $('header.mobile-nav .panel-main').show();
        $('header.mobile-nav .dropdown[data-dropdown="main"]').removeClass('panel-open');
        $('header.mobile-nav .panel-right').one('webkitTransitionEnd otransitionend msTransitionEnd transitionend', (e) => {
          if (parseInt($(e.currentTarget).css('transform')
            .split(',')[4]) !== 0) {
            $('.header-mobile-panel.panel-current').removeClass('panel-current')
              .attr('aria-expanded', false);
          }
        });
      }
    });
  }

  getLanguagePaths() {
    $.ajax({
      type: 'GET',
      url: requestUrls.languagePaths,
      data: {
        path: window.digitalData.page.pageInfo.pagePath
      },
      contentType: 'application/json'
    }).done((r) => {
      this.populateLanguagePaths(r);
    })
      .fail((r) => {
        console.log('there was an error');
      });
  }

  triggerLanguageSelect() {
    $('.top-nav-item[data-dropdown="language"]').one('click', () => {
      this.getLanguagePaths();
    });
    $('.header-mobile-button[data-panel="language"]').one('click', () => {
      this.getLanguagePaths();
    });
  }

  populateLanguagePaths(res) {
    if (res && res.length > 0) {
      $.each(res, (index, obj) => {
        $('.language-list li[data-locale=' + obj.locale + '] a').attr('href', obj.url)
          .click((e) => {
            e.preventDefault();
            e.stopPropagation();
            window.location.assign($(e.currentTarget).attr('href'));
          });
      });
    }
  }
}

export default new GlobalNavigation();
